import React from 'react';
import * as styles from './Confirmation.module.scss';
import classNames from 'classnames';
import formatNumber from '../../../utils/formatNumber';
import Modal from '../../Modal/Modal';
import FormDescription from '../FormDescription/FormDescription';
import PortableText from '@sanity/block-content-to-react';

const Confirmation = ({
  shopingCart,
  totalProfit,
  totalCost,
  shippingData,
  introduction,
}) => {
  const idList = Object.keys(shopingCart);
  const shopingList = Object.values(shopingCart).map(
    ({ name, description, amount, cost, profitt }, index) => {
      if (amount > 0) {
        return (
          <div key={idList[index]} className={styles.line}>
            <div className={classNames(styles.element, styles.text)}>
              <div className={styles.name}>{name}</div>
              <div className={styles.description}>
                <PortableText blocks={description} />
              </div>
            </div>
            <div className={classNames(styles.element)}>
              {formatNumber(amount)}
            </div>
            <div className={classNames(styles.element)}>
              {formatNumber(cost * amount)},-
            </div>
            <div className={classNames(styles.element, styles.profit)}>
              {formatNumber(profitt * amount)},-
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
  );

  const birthday = shippingData.birthday;

  return (
    <div className={styles.confirmation}>
      <FormDescription description={introduction} />
      <Modal>
        <div className={styles.submission}>
          <div className={styles.delivery}>
            <div className={classNames(styles.shippingLine, styles.name)}>
              {shippingData.name}
            </div>
            <div className={classNames(styles.shippingLine)}>
              Født: {birthday.day}.{birthday.month}.{birthday.year}
            </div>
            <div className={classNames(styles.shippingLine)}>
              Telefon: {shippingData.phone}
            </div>
            <div className={classNames(styles.shippingLine)}>
              E-post: {shippingData.email}
            </div>
            <div className={classNames(styles.shippingLine, styles.adress)}>
              {shippingData.adress}, {shippingData.zip} {shippingData.city}
            </div>
          </div>
          <div className={styles.shopingList}>
            <div className={styles.legend}>
              <div className={styles.legendLine}>Produkt</div>
              <div className={styles.legendLine}>Antall</div>
              <div className={styles.legendLine}>Pris</div>
              <div className={styles.legendLine}>Fortjeneste</div>
            </div>
            {shopingList}
          </div>
          <div className={styles.summary}>
            <div className={styles.summaryLine}>
              Dere betaler: <b>{formatNumber(totalCost)},-</b>
            </div>
            <div className={styles.summaryLine}>
              Dere selger for: <b>{formatNumber(totalProfit + totalCost)},-</b>
            </div>
            <div className={classNames(styles.summaryLine, styles.mainLine)}>
              Deres fortjeneste: <b>{formatNumber(totalProfit)},-</b>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Confirmation;
