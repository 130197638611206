// extracted by mini-css-extract-plugin
export var amount = "Delivery-module--amount--P3xAe";
export var birtdayGrid = "Delivery-module--birtdayGrid--tml4l";
export var buttonContainer = "Delivery-module--buttonContainer--JW-kv";
export var definition = "Delivery-module--definition--rGb6d";
export var delivery = "Delivery-module--delivery--x4bq3";
export var form = "Delivery-module--form--8H04r";
export var formGrid = "Delivery-module--formGrid--w-Gfw";
export var modalWrapper = "Delivery-module--modalWrapper--U5wXL";
export var subTitle = "Delivery-module--subTitle--uUXl1";
export var summary = "Delivery-module--summary--72UHO";
export var summaryContainer = "Delivery-module--summaryContainer--vzQGC";
export var summaryMain = "Delivery-module--summaryMain--k1KiZ";
export var termsContainer = "Delivery-module--termsContainer--UMqNK";
export var title = "Delivery-module--title--bwy7P";