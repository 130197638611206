// extracted by mini-css-extract-plugin
export var actions = "ProductSelection-module--actions--hP4FS";
export var amount = "ProductSelection-module--amount--jZ-jD";
export var changeAmount = "ProductSelection-module--changeAmount--yB9Gx";
export var description = "ProductSelection-module--description--sGRTT";
export var fill = "ProductSelection-module--fill--6Xpb5";
export var form = "ProductSelection-module--form--y7lxG";
export var image = "ProductSelection-module--image--w4hc7";
export var input = "ProductSelection-module--input--iLYHX";
export var legend = "ProductSelection-module--legend--+sBvI";
export var order = "ProductSelection-module--order--pM73i";
export var productLine = "ProductSelection-module--productLine--WEUTG";
export var productList = "ProductSelection-module--productList--xi2FB";
export var profit = "ProductSelection-module--profit--kFyAL";
export var specification = "ProductSelection-module--specification--vGKx7";
export var text = "ProductSelection-module--text--R7FAU";
export var title = "ProductSelection-module--title--bd3RH";