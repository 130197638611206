const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const handleSubmit = (event, action) => {
  event.preventDefault();
  const data = { 'form-name': event.target.getAttribute('name') };
  const fields = event.target.querySelectorAll('input');
  fields.forEach((field) => {
    data[field.getAttribute('name')] = field.value;
  });
  console.log(data);
  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode(data),
  })
    .then(() => action)
    .catch((error) => alert(error));
};

export default handleSubmit;
