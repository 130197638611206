import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import * as styles from './ProductSelection.module.scss';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import FormDescription from '../FormDescription/FormDescription';
import classNames from 'classnames';
import formatNumber from '../../../utils/formatNumber';

const ProductSelection = ({
  products,
  setShopingCart,
  shopingCart,
  formValid,
  totalProfit,
  nextOrderStep,
  introduction,
}) => {
  const updateShopingCart = (id, newAmount) => {
    if (newAmount >= 0 && newAmount !== '') {
      const updatedItem = shopingCart[id];
      updatedItem.amount = parseInt(newAmount);
      setShopingCart((prevState) => {
        return {
          ...prevState,
          [id]: updatedItem,
        };
      });
    }
  };

  const addOneItemToCart = (id) => {
    updateShopingCart(id, shopingCart[id].amount + 1);
  };

  const removeOneItemFromCart = (id) => {
    updateShopingCart(id, shopingCart[id].amount - 1);
  };

  const productList = products.map(({ id, title, _rawDescription, image }) => {
    return (
      <div key={id} className={styles.productLine}>
        <div className={styles.image}>
          <GatsbyImage
            image={image.asset.gatsbyImageData}
            alt={`${image.alt}`}
          />
        </div>
        <div className={styles.text}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.description}>
            <PortableText blocks={_rawDescription} />
          </div>
        </div>
        <div className={styles.amount}>
          <button
            className={styles.changeAmount}
            onClick={() => {
              removeOneItemFromCart(id);
            }}
          >
            -
          </button>
          <input
            className={styles.input}
            type={'number'}
            min={0}
            max={200}
            placeholder={shopingCart[id].amount}
            onChange={(event) => {
              updateShopingCart(id, event.target.value);
            }}
          />
          <button
            className={styles.changeAmount}
            onClick={() => {
              addOneItemToCart(id);
            }}
          >
            +
          </button>
        </div>
        <div className={styles.profit}>
          {formatNumber(shopingCart[id].amount * shopingCart[id].profitt)}
          ,-
        </div>
      </div>
    );
  });

  return (
    <div className={styles.order}>
      <FormDescription description={introduction} />
      <Modal>
        <div className={styles.form}>
          <div className={styles.legend}>
            <div
              className={classNames(styles.specification, styles.fill)}
            ></div>
            <div className={styles.specification}>Produkt</div>
            <div className={styles.specification}>Antall</div>
            <div className={styles.specification}>Fortjeneste</div>
          </div>
          <div className={styles.productList}>{productList}</div>
          <div className={styles.actions}>
            <Button
              disabled={formValid === false}
              onClick={() => {
                nextOrderStep();
              }}
            >
              Fortsett
            </Button>
            <div className={styles.profit}>
              Deres fortjeneste: <b>{formatNumber(totalProfit)},-</b>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductSelection;
