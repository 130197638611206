import React from 'react';
import * as styles from './Delivery.module.scss';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import Modal from '../../Modal/Modal';
import FormDescription from '../FormDescription/FormDescription';
import classNames from 'classnames';
import formatNumber from '../../../utils/formatNumber';
import handleSubmit from '../../../utils/handleSubmit';

const Delivery = ({
  totalProfit,
  totalCost,
  setShippingData,
  formValid,
  nextOrderStep,
  introduction,
  shippingData,
  shopingCart,
}) => {
  const updateShippingField = (key, value) => {
    console.log(shippingData);
    setShippingData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const updateBirthdayField = (key, value) => {
    setShippingData((prevState) => {
      const newBirthday = prevState.birthday;
      newBirthday[key] = value;
      return {
        ...prevState,
        birthday: newBirthday,
      };
    });
  };

  const orderFields = [];
  const products = Object.keys(shopingCart);
  for (const id of products) {
    const product = shopingCart[id];
    orderFields.push(
      <input
        key={id}
        name={product.name}
        value={product.amount}
        hidden
        readOnly
      />
    );
  }

  return (
    <div className={styles.delivery}>
      <FormDescription description={introduction} />
      <div className={styles.modalWrapper}>
        <Modal>
          <div className={styles.form}>
            <form
              className={styles.masterForm}
              method="POST"
              name={'bestilling'}
              data-netlify="true"
              onSubmit={(event) => {
                handleSubmit(event, nextOrderStep());
              }}
            >
              <input type="hidden" name="form-name" value="bestilling" />
              <div className={styles.title}>Kjøper</div>
              <div className={styles.formGrid}>
                <Input
                  name={'name'}
                  placeholder={'Fult navn'}
                  onChange={(event) => {
                    updateShippingField(event.target.name, event.target.value);
                  }}
                />
                <Input
                  name={'email'}
                  placeholder={'E-post'}
                  onChange={(event) => {
                    updateShippingField(event.target.name, event.target.value);
                  }}
                />
              </div>
              <div className={styles.subTitle}>Fødselsdato</div>
              <div className={styles.birtdayGrid}>
                <Input
                  name={'day'}
                  placeholder={'Dag'}
                  type={'number'}
                  onChange={(event) => {
                    updateBirthdayField(event.target.name, event.target.value);
                  }}
                />
                <Input
                  name={'month'}
                  placeholder={'Måned'}
                  type={'number'}
                  onChange={(event) => {
                    updateBirthdayField(event.target.name, event.target.value);
                  }}
                />
                <Input
                  name={'year'}
                  placeholder={'År'}
                  type={'number'}
                  onChange={(event) => {
                    updateBirthdayField(event.target.name, event.target.value);
                  }}
                />
              </div>
              <div className={styles.formGrid}>
                <Input
                  name={'phone'}
                  placeholder={'Telefon'}
                  onChange={(event) => {
                    updateShippingField(event.target.name, event.target.value);
                  }}
                />
                <Input
                  name={'adress'}
                  placeholder={'Adresse'}
                  onChange={(event) => {
                    updateShippingField(event.target.name, event.target.value);
                  }}
                />
                <Input
                  name={'zip'}
                  placeholder={'Postnummer'}
                  onChange={(event) => {
                    updateShippingField(event.target.name, event.target.value);
                  }}
                />
                <Input
                  name={'city'}
                  placeholder={'Poststed'}
                  onChange={(event) => {
                    updateShippingField(event.target.name, event.target.value);
                  }}
                />
              </div>
              <div className={styles.subTitle}>Levering</div>
              <div className={styles.termsContainer}>
                <input
                  type={'checkbox'}
                  id={'sameAdress'}
                  name="sameAdress"
                  defaultChecked={true}
                  onChange={(event) => {
                    updateShippingField(
                      event.target.name,
                      event.target.checked
                    );
                  }}
                />
                <label htmlFor="sameAdress">
                  Leveringsadresse er lik kjøpers adresse
                </label>
              </div>
              {shippingData['sameAdress'] !== true && (
                <div className={styles.formGrid}>
                  <Input
                    name={'deliveryName'}
                    placeholder={'Fult navn'}
                    onChange={(event) => {
                      updateShippingField(
                        event.target.name,
                        event.target.value
                      );
                    }}
                  />
                  <Input
                    name={'deliveryEmail'}
                    placeholder={'E-post'}
                    onChange={(event) => {
                      updateShippingField(
                        event.target.name,
                        event.target.value
                      );
                    }}
                  />
                  <Input
                    name={'deliveryPhone'}
                    placeholder={'Telefon'}
                    onChange={(event) => {
                      updateShippingField(
                        event.target.name,
                        event.target.value
                      );
                    }}
                  />
                  <Input
                    name={'deliveryAdress'}
                    placeholder={'Adresse'}
                    onChange={(event) => {
                      updateShippingField(
                        event.target.name,
                        event.target.value
                      );
                    }}
                  />
                  <Input
                    name={'deliveryZip'}
                    placeholder={'Postnummer'}
                    onChange={(event) => {
                      updateShippingField(
                        event.target.name,
                        event.target.value
                      );
                    }}
                  />
                  <Input
                    name={'deliveryCity'}
                    placeholder={'Poststed'}
                    onChange={(event) => {
                      updateShippingField(
                        event.target.name,
                        event.target.value
                      );
                    }}
                  />
                </div>
              )}
              {/*<div className={styles.subTitle}>Lag</div>*/}
              <div>
                <Input
                  name={'type'}
                  placeholder={'Russ / Idrettslag / Skole?'}
                  onChange={(event) => {
                    updateShippingField(event.target.name, event.target.value);
                  }}
                />
              </div>
              <div className={styles.termsContainer}>
                <input
                  type={'checkbox'}
                  id={'terms'}
                  name="terms"
                  onChange={(event) => {
                    updateShippingField(
                      event.target.name,
                      event.target.checked
                    );
                  }}
                />
                <label htmlFor="terms">
                  Jeg bekrefter at jeg godtar
                  <a href="/terms" target={'_blank'} rel="noreferrer">
                    betingelsene
                  </a>{' '}
                  og at kjøper er over 18 år.
                </label>
              </div>
              {orderFields}
              <div className={styles.buttonContainer}>
                <Button disabled={formValid === true ? false : true}>
                  Bestill
                </Button>
              </div>
            </form>
          </div>
        </Modal>
        <Modal>
          <div className={styles.summary}>
            <h3 className={styles.title}>Oppsummering</h3>
            <div className={styles.summaryContainer}>
              <div className={styles.definition}>Dere selger varer for:</div>
              <div className={styles.amount}>
                {formatNumber(totalProfit + totalCost)}
              </div>
            </div>
            <div className={styles.summaryContainer}>
              <div className={styles.definition}>Dere betaler for varene:</div>
              <div className={styles.amount}>{formatNumber(totalCost)}</div>
            </div>
            <div
              className={classNames(
                styles.summaryContainer,
                styles.summaryMain
              )}
            >
              <div className={styles.definition}>Deres fortjeneste</div>
              <div className={styles.amount}>{formatNumber(totalProfit)}</div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Delivery;
