import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import * as styles from './Order.module.scss';
import ProductSelection from './ProductSelection/ProductSelection';
import Delivery from './Delivery/Delivery';
import Confirmation from './Confirmation/Confirmation';
import Wrapper from '../Wrapper/Wrapper';
import classNames from 'classnames';

const Order = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allSanityProducts {
            nodes {
              ...ProductsData
            }
          }
          sanityTexts(_id: { eq: "texts" }) {
            _rawOrder(resolveReferences: { maxDepth: 10 })
            _rawDelivery(resolveReferences: { maxDepth: 10 })
            _rawConfirmation(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={(data) => {
        const emptyShopingCart = {};
        const texts = data.sanityTexts;
        data.allSanityProducts.nodes.forEach((product) => {
          emptyShopingCart[product.id] = {
            name: product.title,
            description: product._rawDescription,
            cost: product.price,
            profitt: product.profitt,
            active: product.active,
            amount: 0,
          };
        });
        return (
          <OrderForm
            emptyShopingCart={emptyShopingCart}
            products={data.allSanityProducts.nodes}
            orderIntroduction={texts._rawOrder}
            deliveryIntroduction={texts._rawDelivery}
            confirmationIntroduction={texts._rawConfirmation}
          />
        );
      }}
    />
  );
};

const OrderForm = ({
  products,
  emptyShopingCart,
  orderIntroduction,
  deliveryIntroduction,
  confirmationIntroduction,
}) => {
  const [shopingCart, setShopingCart] = useState(emptyShopingCart);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const [shippingData, setShippingData] = useState({
    name: '',
    birthday: { day: '', month: '', year: '' },
    phone: '',
    email: '',
    adress: '',
    zip: '',
    city: '',
    terms: false,
    sameAdress: true,
  });

  const [formValid, setFormValid] = useState(false);
  const [orderStep, setOrderStep] = useState(0);

  const [orderSubmitted, setOrderSubmitted] = useState(false);

  const nextOrderStep = () => {
    if (formValid) {
      setOrderStep(orderStep + 1);
      setFormValid(false);
    }
  };

  const previousOrderStep = () => {
    if (orderStep > 0) {
      setOrderStep(orderStep - 1);
      setFormValid(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [orderStep]);

  useEffect(() => {
    let newProfitt = 0;
    let newCost = 0;
    const shopingCartKeys = Object.keys(shopingCart);
    for (const id of shopingCartKeys) {
      newProfitt += shopingCart[id].amount * shopingCart[id].profitt;
      newCost += shopingCart[id].amount * shopingCart[id].cost;
    }
    setTotalProfit(newProfitt);
    setTotalCost(newCost);
    if (orderStep === 0) {
      setFormValid(newCost > 0);
    }
  }, [shopingCart, orderStep]);

  useEffect(() => {
    if (orderStep === 1) {
      const adressInputs = Object.values(shippingData);
      const birtdayInputs = Object.values(shippingData.birthday);
      if (
        !adressInputs.includes('') &&
        !birtdayInputs.includes('') &&
        shippingData.terms
      ) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  }, [shippingData, orderStep]);

  useEffect(() => {
    if (orderStep === 2) {
      if (orderSubmitted === false) {
        setOrderSubmitted(true);
      }
    }
  }, [
    shopingCart,
    shippingData,
    orderStep,
    totalProfit,
    totalCost,
    orderSubmitted,
  ]);

  return (
    <div className={styles.forms}>
      <Wrapper>
        <div
          className={classNames(styles.step, {
            [styles.active]: orderStep === 0,
          })}
        >
          <ProductSelection
            products={products}
            shopingCart={shopingCart}
            totalProfit={totalProfit}
            setShopingCart={setShopingCart}
            formValid={formValid}
            nextOrderStep={nextOrderStep}
            introduction={orderIntroduction}
          />
        </div>
        <div
          className={classNames(styles.step, {
            [styles.active]: orderStep === 1,
          })}
        >
          {' '}
          <Delivery
            totalProfit={totalProfit}
            totalCost={totalCost}
            setShippingData={setShippingData}
            shippingData={shippingData}
            formValid={formValid}
            nextOrderStep={nextOrderStep}
            previousOrderStep={previousOrderStep}
            introduction={deliveryIntroduction}
            shopingCart={shopingCart}
          />
        </div>
        <div
          className={classNames(styles.step, {
            [styles.active]: orderStep === 2,
          })}
        >
          <Confirmation
            shopingCart={shopingCart}
            totalProfit={totalProfit}
            totalCost={totalCost}
            shippingData={shippingData}
            introduction={confirmationIntroduction}
          />
        </div>
      </Wrapper>
    </div>
  );
};

export default Order;
