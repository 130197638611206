import * as React from 'react';
import Layout from '../components/Layout/Layout';
import Order from '../components/Order/Order';

const Bestill = () => {
  return (
    <Layout theme="dark" center={true} hideFooter={true}>
      <Order />
    </Layout>
  );
};

export default Bestill;
