// extracted by mini-css-extract-plugin
export var adress = "Confirmation-module--adress--laks9";
export var confirmation = "Confirmation-module--confirmation--ALnKg";
export var delivery = "Confirmation-module--delivery--o9Sru";
export var description = "Confirmation-module--description--l3Bma";
export var element = "Confirmation-module--element--e1z8o";
export var legend = "Confirmation-module--legend--4O5oi";
export var legendLine = "Confirmation-module--legendLine--S28UZ";
export var line = "Confirmation-module--line--bXRu2";
export var mainLine = "Confirmation-module--mainLine--RrvFR";
export var name = "Confirmation-module--name--mf+JI";
export var profit = "Confirmation-module--profit--UiTfX";
export var shippingLine = "Confirmation-module--shippingLine--HYAui";
export var shopingList = "Confirmation-module--shopingList--zJNjz";
export var submission = "Confirmation-module--submission---lJ3x";
export var summary = "Confirmation-module--summary--rs0sS";
export var summaryLine = "Confirmation-module--summaryLine--Z6Ach";
export var text = "Confirmation-module--text--79ddh";