import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Button.module.scss';
import classNames from 'classnames';
import { Link } from 'gatsby';

const Button = ({ variant, children, to, ...props }) => {
  if (to == null) {
    return (
      <button className={classNames(styles.button, styles[variant])} {...props}>
        {children}
      </button>
    );
  } else {
    return (
      <Link to={to}>
        <button
          className={classNames(styles.button, styles[variant])}
          {...props}
        >
          {children}
        </button>
      </Link>
    );
  }
};

Button.propTypes = {
  to: PropTypes.string,
  variant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  to: null,
  variant: 'main',
  children: null,
};

export default Button;
