import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './FormDescription.module.scss';
import PortableText from '@sanity/block-content-to-react';

const FormDescription = ({ description }) => {
  return (
    <div className={styles.wrapper}>
      {description && <PortableText blocks={description} />}
    </div>
  );
};

FormDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.array,
};

FormDescription.defaultProps = {
  title: null,
  description: null,
};

export default FormDescription;
