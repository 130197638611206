import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Wrapper.module.scss';

const Wrapper = ({ children }) => {
  return <div className={styles.wrapper}>{children}</div>;
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

Wrapper.defaultProps = {
  children: null,
};

export default Wrapper;
