import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Modal.module.scss';

const Modal = ({ children, ...props }) => {
  return (
    <section className={styles.modal} {...props}>
      {children}
    </section>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  children: null,
};

export default Modal;
